<template>
  <RouterView></RouterView>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.pageBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.highLightText {
  color: rgb(var(--primary-6));
  cursor: pointer;
}
</style>
