import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
// import '@arco-themes/vue-xg-scrm/index.less';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { Message } from "@arco-design/web-vue";
import '@arco-design/web-vue/dist/arco.css';



import router from './router';


const app = createApp(App);
app.config.globalProperties.$message = Message;
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(router)
app.mount('#app');