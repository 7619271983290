
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/sales/record",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/",
    name: "管理",
    component: () => import("@/pages/menu/index.vue"),
    meta: { title: "管理", login: true },
    children: [
      {
        path: "/sales/record",
        component: () => import("@/pages/manage/salesRecord.vue"),
        meta: {
          title: "门店销售记录",
          icon: "unordered-list",
          login: true,
        },
      },
      {
        path: "/settlement",
        component: () => import("../App.vue"),
        meta: {
          title: "跨店结算",
          icon: "relation",
          login: true,
        },
        children:[
          {
            path: "/settlement/list",
            component: () => import("@/pages/manage/settlement/list.vue"),
            meta: {
              title: "结算列表",
              icon: "settings",
              login: true,
            },
          },
          {
            path: "/settlement/detail",
            component: () => import("@/pages/manage/settlement/detail.vue"),
            meta: {
              title: "跨店结算详情",
              icon: "relation",
              login: true,
              disabled: true,
              father:'/settlement/list'
            },
          },
        ]
      },


      {
        path: "/setting",
        component: () => import("@/pages/manage/setting.vue"),
        meta: {
          title: "基础设置",
          icon: "settings",
          login: true,
        },
      },
      {
        path: "/account",
        component: () => import("../App.vue"),
        meta: {
          title: "门店账户管理",
          icon: "user-group",
          login: true,
        },
        children:[
          {
            path: "/account/shop",
            component: () => import("@/pages/manage/accountManage/shopList.vue"),
            meta: {
              title: "店铺管理",
              icon: "settings",
              login: true,
            },
          },
          {
            path: "/account/shop/add",
            component: () => import("@/pages/manage/accountManage/addShop.vue"),
            meta: {
              title: "添加店铺",
              icon: "settings",
              login: true,
              disabled: true,
              father:'/account/shop'
            },
          },
          {
            
            path: "/account/account",
            component: () => import("@/pages/manage/accountManage/accountList.vue"),
            meta: {
              title: "账户管理",
              icon: "settings",
              login: true,
            },
          },
        ]
      },
    ],
  },
];
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});
export default router;